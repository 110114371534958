<template>
  <b-modal
    id="modal-satuan"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal--large"
  >
    <div class="section">
      <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
        <b-row>
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div
              style="cursor: pointer;"
              @click="directPage"
            >
              <feather-icon
                size="24"
                class="text-dark font-weight-bolder"
                icon="ArrowLeftIcon"
              />
              <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
            </div>
          </b-col>
          <b-col
            cols="6"
            class="d-flex justify-content-center align-items-center"
          >
            <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
              Informasi Satuan
            </h1>
          </b-col>
          <b-col
            cols="3"
            class="text-right"
          >
            <b-button
              class="px-3"
              @click="editUuid == null ? createItem() : updateItem()"
            >
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </header>
      <b-container class="mt-5 pt-5">
        <b-row class="justify-content-center h-100 bg-white pb-5">
          <b-col
            cols="12"
            md="5"
          >
            <b-row>
              <!-- Nama Satuan -->
              <b-col
                cols="12"
                md="12"
                class="mb-2 custom__form--input"
              >
                <label for="v-nama-satuan">Nama Satuan <span class="text-danger">*</span></label>
                <b-form-group>
                  <b-form-input
                    id="v-nama-satuan"
                    v-model="formPayload.name"
                    type="text"
                    placeholder="Nama Satuan"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <!-- Deskripsi Satuan -->
              <b-col
                cols="12"
                md="12"
                class="mb-3 custom__form--input"
              >
                <label for="v-deskripsi">Deskripsi Satuan</label>
                <b-form-group>
                  <b-form-textarea
                    id="v-deskripsi"
                    v-model="formPayload.description"
                    rows="5"
                    placeholder="Deskripsi Satuan"
                    class="custom__textarea"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>
<script>
/* eslint-disable vue/require-default-prop */
import {
  BModal, BContainer, BRow, BCol, BButton, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BContainer,
  },
  props: {
    formData: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
    createItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    editId: {
      type: String,
    },
    updateItem: {
      type: Function,
    },
    cleanUpForm: {
      type: Function,
    },
  },
  watch: {
    editId(value) {
      this.editUuid = value
    },
    formData(value) {
      this.formPayload = value
    },
    formPayload: {
      handler(value) {
        this.$emit('getPayload', value)
      },
      deep: true,
    },
  },
  data() {
    return {
      formPayload: {
        name: '',
        description: '',
      },
      editUuid: null,
    }
  },
  methods: {
    directPage() {
      this.cleanUpForm()
      this.$bvModal.hide('modal-satuan')
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
</style>
